export interface Card {
  id: string;
  date: number;
  content: string;
  img: string;
  attr?: string;
  isFlipped?: boolean;
}

export interface GameState {
  win?: boolean;
  number: number;
  rightCards: Card[];
  wrongCards: Card[];
  nextCard: Card[] | undefined;
  cardsToPlay: Card[];
}

export interface GameHistoric {
  [key: string]: {
    win?: boolean;
    number: number;
    rightCardsIds: string[];
    wrongCardsIds: string[];
    nextCardId: string | undefined;
    cardsToPlayIds: string[];
  };
}

export enum GameMode {
  DAILY = 'daily',
  FAMOUS_CHARACTERS = 'famous_characters',
  WORLD_HISTORY = 'world_history',
  INVENTION = 'invention',
}
