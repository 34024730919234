import { Card, GameMode } from '../../utils/types';
import { handleShare } from './handleShare';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface ShareResultsProps {
  wrongCards: Card[];
  rightCards: Card[];
  gameMode: GameMode;
}

export function ShareResults({
  wrongCards,
  rightCards,
  gameMode,
}: ShareResultsProps): JSX.Element {
  const { t } = useTranslation();
  const copyText = t('endGameView.copy');
  const noCopyText = t('endGameView.noCopy');
  return (
    <>
      <button
        className="text-btn share-btn"
        onClick={() =>
          handleShare(wrongCards, rightCards, gameMode, copyText, noCopyText)
        }
      >
        {t('shareResults')}
      </button>
      <ToastContainer />
    </>
  );
}
