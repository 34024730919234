import { createContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import Board from './components/Board/Board';
import { HomePage } from './components/HomePage/HomePage';
import { useLocalStorage } from './hooks/localStorage';
import { GameHistoric, GameMode } from './utils/types';
import useWindowHeight from './hooks/useWindowHeight';

type GameHistoricContextType = {
  gameHistoric: GameHistoric;
  setGameHistoric: React.Dispatch<React.SetStateAction<GameHistoric>>;
};
export const GameHistoricContext = createContext<GameHistoricContextType>({
  gameHistoric: {},
  setGameHistoric: () => {},
});

function App() {
  const [gameHistoric, setGameHistoric] = useLocalStorage<GameHistoric>(
    `chronoline_daily_game_historic`,
    {}
  );
  const windowHeight = useWindowHeight();

  return (
    <>
      <GameHistoricContext.Provider value={{ gameHistoric, setGameHistoric }}>
        <BrowserRouter>
          <div className="app-ctn" style={{height: windowHeight}}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route
                path="/world-history"
                element={
                  <Board
                    setGameHistoric={setGameHistoric}
                    pointsToWin={10}
                    lifes={5}
                    gameMode={GameMode.WORLD_HISTORY}
                  />
                }
              />
              <Route
                path="/famous-characters"
                element={
                  <Board
                    setGameHistoric={setGameHistoric}
                    lifes={5}
                    pointsToWin={10}
                    gameMode={GameMode.FAMOUS_CHARACTERS}
                  />
                }
              />
              <Route
                path="/daily"
                element={
                  <Board
                    setGameHistoric={setGameHistoric}
                    lifes={5}
                    pointsToWin={10}
                    gameMode={GameMode.DAILY}
                  />
                }
              />
              <Route
                path="/inventions"
                element={
                  <Board
                    setGameHistoric={setGameHistoric}
                    lifes={5}
                    pointsToWin={10}
                    gameMode={GameMode.INVENTION}
                  />
                }
              />
            </Routes>
          </div>
        </BrowserRouter>
      </GameHistoricContext.Provider>
    </>
  );
}

export default App;
