import { useState } from 'react';
import i18n from '../../i18n';
import './Settings.scss';
import { useTranslation } from 'react-i18next';

export function Settings() {
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };
  const [selectedLanguage, setSelectedLanguage] = useState(
    i18n.language.slice(0, 2)
  );
  const { t } = useTranslation();

  return (
    <div className="settings-ctn">
      <h1>{t('settings.title')}</h1>
      <div className="language">
        <h2>{t('settings.language')}</h2>
        <select
          value={selectedLanguage}
          onChange={(e: any) => changeLanguage(e.target.value)}
        >
          <option value={'en'}>en</option>
          <option value={'es'}>es</option>
        </select>
      </div>
    </div>
  );
}
