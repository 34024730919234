import { useTranslation } from 'react-i18next';
import './About.scss';

export function About() {
  const { t } = useTranslation();
  const linkedInIcon = <i className="devicon-linkedin-plain"></i>;

  return (
    <div className="about-ctn">
      <h1>{t('aboutUs.title')}</h1>
      <p>{t('aboutUs.game')}</p>
      <p>
        {t('footer.feedbackShare')}
        <a href="mailto:chronoline.game@gmail.com">
          {' '}
          chronoline.game@gmail.com
        </a>
      </p>
      <p>{t('footer.developedBy')}</p>
      <a href="https://www.linkedin.com/in/maria-ten/">
        {' '}
        {linkedInIcon} María Ten Sierra
      </a>
      <a
        style={{ marginLeft: '30px' }}
        href="https://www.linkedin.com/in/lluis-simon-argemi/"
      >
        {' '}
        {linkedInIcon} Lluís Simón Argemí
      </a>
    </div>
  );
}
