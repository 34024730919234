import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentDateNumber } from '../../utils/getCurrentDate';
import { useLocalStorage } from '../../hooks/localStorage';
import { Countdown } from '../Countdown/Countdown';
import { Stats } from '../StatisticsPage/StatisticsPage';
import { ShareResults } from './ShareResults';
import { GameMode } from '../../utils/types';
import ChronolinePopup from '../ChronolinePopup/ChronolinePopup';

interface EndGameViewProps {
  gameState: any;
  isDaily: boolean;
  startOver: () => void;
  gameMode: GameMode;
}

export function EndGameView({
  gameState,
  isDaily,
  startOver,
  gameMode,
}: EndGameViewProps): JSX.Element {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [popup, setPopup] = useLocalStorage<number>('popup', 0);

  useEffect(() => {
    if (
      (gameState.win === true || gameState.win === false) &&
      (popup === 0 || popup < getCurrentDateNumber())
    ) {
      setTimeout(() => {
        setIsOpen(true);
        setPopup(getCurrentDateNumber());
      }, 2000);
    }
  }, [gameState.win, popup, setPopup]);

  return (
    <>
      {gameState.win ? (
        <>
          <h1>{t('endGameView.winMessage')} 🎉</h1>
          {isDaily ? (
            <>
              <ShareResults
                rightCards={gameState.rightCards}
                wrongCards={gameState.wrongCards}
                gameMode={gameMode}
              />
              <Countdown />
              <ChronolinePopup isOpen={isOpen} setIsOpen={setIsOpen}>
                <Stats />
              </ChronolinePopup>
            </>
          ) : (
            <div className="endgame-btns">
              <button className="text-btn" onClick={startOver}>
                {t('endGameView.playAgain')}
              </button>
              <ShareResults
                rightCards={gameState.rightCards}
                wrongCards={gameState.wrongCards}
                gameMode={gameMode}
              />
            </div>
          )}
        </>
      ) : (
        gameState.wrongCards.length === 5 && (
          <>
            <h1> {t('endGameView.loseMessage')} 🤓</h1>
            {isDaily ? (
              <>
                <ShareResults
                  wrongCards={gameState.wrongCards}
                  rightCards={gameState.rightCards}
                  gameMode={gameMode}
                />
                <Countdown />
                <ChronolinePopup isOpen={isOpen} setIsOpen={setIsOpen}>
                  <Stats />
                </ChronolinePopup>
              </>
            ) : (
              <div className="endgame-btns">
                <button className="text-btn" onClick={startOver}>
                  {t('endGameView.playAgain')}
                </button>
                <ShareResults
                  wrongCards={gameState.wrongCards}
                  rightCards={gameState.rightCards}
                  gameMode={gameMode}
                />
              </div>
            )}
          </>
        )
      )}
    </>
  );
}
