import { useLayoutEffect, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Card } from '../../utils/types';
import './TimelineCard.scss';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

interface CardProps {
  card: Card;
  index: number;
  isDragDisabled: boolean;
  cardToFlip?: boolean;
}

export default function TimelineCard({
  card,
  index,
  isDragDisabled,
  cardToFlip,
}: CardProps) {
  const [isCardLoaded, setIsCardLoaded] = useState<boolean>(false);
  const [flipCard, setFlipCard] = useState<boolean | undefined>(undefined);
  const { t } = useTranslation();
  const imageUrl = useMemo(() => process.env.PUBLIC_URL + card.img, [card.img]);
  useLayoutEffect(() => {
    const img = new window.Image();
    img.src = imageUrl;
    img.onload = () => {
      setIsCardLoaded(true);
    };
    setTimeout(() => {
      setIsCardLoaded(true);
    }, 2000);
  }, [imageUrl]);

  function flipCardToShowDescription() {
    if (card.isFlipped) {
      if (flipCard === undefined && cardToFlip)
        setTimeout(() => {
          setFlipCard(false);
        }, 2);
      else setFlipCard(!flipCard);
    }
  }

  if (!isCardLoaded && index === 0 && !isDragDisabled) {
    return null;
  }

  return (
    <Draggable
      isDragDisabled={isDragDisabled}
      draggableId={card.id}
      index={index}
    >
      {(provided) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="card">
              <div
                id={card.id}
                onClick={flipCardToShowDescription}
                className={`card-inner ${
                  (cardToFlip || card.isFlipped) && !flipCard ? 'flipped' : ''
                }`}
              >
                <div className="card-front">
                  {card.isFlipped ? (
                    <>
                      <p className="card-description">
                        <span className="card-description-date">{`${card.date}`}</span>
                        {t(`descriptions.${card.id}`)}
                      </p>
                    </>
                  ) : (
                    <>
                      <img src={imageUrl} alt={t(`cards.${card.id}`)!}></img>
                      <p className="card-text">{t(`cards.${card.id}`)}</p>
                    </>
                  )}
                </div>
                <div className="card-back">
                  <span className="show-more-icon">
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </span>
                  <img src={imageUrl} alt={card.content}></img>
                  <p className={'card-date'}>{`${card.date}`}</p>
                  <p className="card-text">{t(`cards.${card.id}`)}</p>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
}
