import { Card } from './types';

export function checkCorrect(
  index: number,
  playedCards: Card[],
  nextCard: Card[]
): boolean {
  if (nextCard && index > 0 && nextCard[0].date < playedCards[index - 1].date) {
    return false;
  }
  if (
    nextCard &&
    index < playedCards.length - 1 &&
    nextCard[0].date > playedCards[index + 1].date
  ) {
    return false;
  }
  return true;
}
