import { Instructions } from '../Instructions/Instructions';
import 'reactjs-popup/dist/index.css';
import './Header.scss';
import { Stats } from '../StatisticsPage/StatisticsPage';
import {
  faBarChart,
  faUser,
  faCircleQuestion,
  faGear,
} from '@fortawesome/free-solid-svg-icons';
import { Settings } from '../Settings/Settings';
import { About } from '../About/About';
import HeaderIcon from '../HeaderIcon/HeaderIcon';

export function Header(): JSX.Element {
  return (
    <>
      <header className="header-ctn">
        <div style={{ display: 'flex' }}>
          <HeaderIcon icon={faGear}>
            <Settings />
          </HeaderIcon>
          <HeaderIcon icon={faBarChart}>
            <Stats />
          </HeaderIcon>
        </div>
        <h1 className="chronoline-title">Chronoline</h1>
        <div style={{ display: 'flex' }}>
          <HeaderIcon icon={faCircleQuestion}>
            <Instructions />
          </HeaderIcon>
          <HeaderIcon icon={faUser}>
            <About />
          </HeaderIcon>
        </div>
      </header>
    </>
  );
}
