import './ChronolinePopup.scss';
import Popup from 'reactjs-popup';

type ChronolinePopupProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactElement;
};

export default function ChronolinePopup({
  isOpen,
  setIsOpen,
  children,
}: ChronolinePopupProps) {
  const closePopup = () => setIsOpen(false);

  return (
    <Popup modal open={isOpen === true} onClose={closePopup}>
      <button className="close" onClick={closePopup}>
        &times;
      </button>
      {children}
    </Popup>
  );
}
