import { useEffect, useState } from 'react';

export default function useWindowHeight() {
  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    function handleResize() {
      const screenHeight = window.innerHeight;
      setScreenHeight(screenHeight);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return screenHeight - 1;
}
