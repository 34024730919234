import { useNavigate } from 'react-router-dom';
import { Header } from '../Header/Header';
import './HomePage.scss';
import { useTranslation } from 'react-i18next';

interface GameModeProps {
  gamemode: string;
  subtitle: string;
  navTo: string;
}

export function HomePage(): JSX.Element {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const GameMode = ({ gamemode, subtitle, navTo }: GameModeProps) => {
    return (
      <div className="gamemode-button">
        <div className="titles-ctn">
          <h1 className="title">{gamemode}</h1>
          <p className="subtitle">{subtitle}</p>
        </div>
        <button className="play-button" onClick={() => navigate(navTo)}>
          {t('homePage.play')}
        </button>
      </div>
    );
  };
  return (
    <>
      <div className="home-page-ctn">
        <div className="main-ctn">
          <Header />
          <div className="home-buttons-ctn">
            <GameMode
              gamemode={t('homePage.dailyGame.title')}
              subtitle={t('homePage.dailyGame.subtitle')}
              navTo="daily"
            />
            <GameMode
              gamemode={t('homePage.worldHistory.title')}
              subtitle={t('homePage.worldHistory.subtitle')}
              navTo="world-history"
            />
            <GameMode
              gamemode={t('homePage.famousCharacters.title')}
              subtitle={t('homePage.famousCharacters.subtitle')}
              navTo="famous-characters"
            />
            <GameMode
              gamemode={t('homePage.inventions.title')}
              subtitle={t('homePage.inventions.subtitle')}
              navTo="inventions"
            />
          </div>
        </div>
        <footer className="footer-ctn">
          <p>
            {t('footer.feedbackShare')}
            <a className="email" href="mailto:chronoline.game@gmail.com">
              chronoline.game@gmail.com
            </a>
          </p>
        </footer>
      </div>
    </>
  );
}
